<template>
  <div @keyup="cli_search_j" class="Navigation_box">
    
    <el-row :gutter="20">
      <el-col :span="3" v-for="(a, index) in SearchEngines" :key="index">
        <div class="grid-content bg-purple" @click="cSearchEngines(index)"
          :class="{ 'activeCss': SearchEngines_i == index }">
          {{ SearchEngines[index].engine }}</div>
      </el-col>
    </el-row>
    <div style="margin-top: 15px;margin-bottom: 15px;">
      <el-autocomplete placeholder="请输入搜索内容，然后按回车搜索" v-model="input" :popper-append-to-body="false"
        :trigger-on-focus="false" :autofocus="true" :fetch-suggestions="querySearchAsync" class="input-with-select"
        @select="handleSelect" size="medium">
        <el-button slot="append" icon="el-icon-search" @click="cli_search()"></el-button>
      </el-autocomplete>
    </div>
    <el-row :gutter="20" type="flex" justify="center" align="middle">
    </el-row>
    <el-row :gutter="20" type="flex" justify="center" align="middle">
      <el-col :span="3" v-for="(a, index) in SearchEngines[SearchEngines_i].secondary" :key="index">
        <div class="grid-content bg-purple" @click="cSearchEngines2(index)"
          :class="{ 'activeCss': SearchEngines_i2 == index }">{{ a.name }}</div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import axios from 'axios';
export default {

  name: "TopNavigation",
  data() {
    return {
      input: '',
      SearchEngines: [{
        engine: '百度',
        secondary: [{
          name: "百度搜索",
          url: 'https://www.baidu.com/s?tn=68018901_2_oem_dg&ie=utf-8&wd='
        }],

      },

      {
        engine: '抖音',
        secondary: [{
          name: "抖音搜索",
          url: 'https://www.douyin.com/search/',
        }],


      },
      {
        engine: '微信',
        secondary: [{
          name: "微信文章",
          url: "https://weixin.sogou.com/weixin?ie=utf8&s_from=input&_sug_=y&_sug_type_=&type=2&query="
        }, {
          name: "公众号",
          url: "https://weixin.sogou.com/weixin?ie=utf8&s_from=input&_sug_=y&_sug_type_=&type=1&query="
        },],

      },
      {
        engine: '知乎',
        secondary: [{
          name: "知乎搜索",
          url: "https://www.zhihu.com/search?type=content&q="
        }],

      },
      {
        engine: '研发',
        secondary: [{
          name: "CSDN",
          url: "https://so.csdn.net/so/search?spm=1000.2115.3001.4498&q="
        }],

      }, {
        engine: '产品',
        secondary: [{
          name: "人人产品经理",
          url: "https://api.woshipm.com/search/list.html?key="
        }, {
          name: "七麦",
          url: "https://www.qimai.cn/search/index/country/cn/search/"
        }],

      },
      {
        engine: '设计',
        secondary: [{
          name: "美叶",
          url: "https://www.meiye.art/search/"
        }, {
          name: "花瓣",
          url: "https://huaban.com/search?q="
        }, {
          name: "Unsplash",
          url: "https://unsplash.com/s/photos/"
        }, {
          name: "Freepik",
          url: "https://www.freepik.com/search?format=search&query="
        },],

      },
      {
        engine: '管理者',
        secondary: [{
          name: "36氪",
          url: "https://www.36kr.com/search/articles/"
        }, {
          name: "凤凰网",
          url: "https://so.ifeng.com/?q="
        }, {
          name: "头条",
          url: "https://so.toutiao.com/search?dvpf=pc&source=input&keyword="
        }, {
          name: "央视网",
          url: "https://search.cctv.com/search.php?qtext="
        },],

      },
      ],
      SearchEngines_i: 0,
      SearchEngines_i2: 0,
      restaurants: [],
      vlu_a: [],
      vlu_a2: [],
      vlu_o: {},
      link: [
        [{
          name: 'elemeent',
          link: 'https://element.eleme.io'
        }, {
          name: 'elemeent',
          link: 'https://element.eleme.io'
        }, {
          name: 'elemeent',
          link: 'https://element.eleme.io'
        }, {
          name: 'elemeent',
          link: 'https://element.eleme.io'
        }, {
          name: 'elemeent',
          link: 'https://element.eleme.io'
        }, {
          name: 'elemeent',
          link: 'https://element.eleme.io'
        }], [{
          name: 'elemeent',
          link: 'https://element.eleme.io'
        }, {
          name: 'elemeent',
          link: 'https://element.eleme.io'
        }, {
          name: 'elemeent',
          link: 'https://element.eleme.io'
        }, {
          name: 'elemeent',
          link: 'https://element.eleme.io'
        }, {
          name: 'elemeent',
          link: 'https://element.eleme.io'
        }, {
          name: 'elemeent',
          link: 'https://element.eleme.io'
        }], [{
          name: 'elemeent',
          link: 'https://element.eleme.io'
        }, {
          name: 'elemeent',
          link: 'https://element.eleme.io'
        }, {
          name: 'elemeent',
          link: 'https://element.eleme.io'
        }, {
          name: 'elemeent',
          link: 'https://element.eleme.io'
        }, {
          name: 'elemeent',
          link: 'https://element.eleme.io'
        }, {
          name: 'elemeent',
          link: 'https://element.eleme.io'
        }], [{
          name: 'elemeent',
          link: 'https://element.eleme.io'
        }, {
          name: 'elemeent',
          link: 'https://element.eleme.io'
        }, {
          name: 'elemeent',
          link: 'https://element.eleme.io'
        }, {
          name: 'elemeent',
          link: 'https://element.eleme.io'
        }, {
          name: 'elemeent',
          link: 'https://element.eleme.io'
        }, {
          name: 'elemeent',
          link: 'https://element.eleme.io'
        }]

      ],
    }
  },
  methods: {
    cSearchEngines(i) {

      this.SearchEngines_i = i
      this.SearchEngines_i2 = 0
      if (condition) {
        
      }
      localStorage.setItem('SearchEngines_i', this.SearchEngines_i);
      localStorage.setItem('SearchEngines_i2', this.SearchEngines_i2);
    },
    cSearchEngines2(i) {

      this.SearchEngines_i2 = i
      localStorage.setItem('SearchEngines_i2', this.SearchEngines_i2);

    },
    cli_search() {
      let url = this.SearchEngines[this.SearchEngines_i].secondary[this.SearchEngines_i2].url + this.input
      window.open(url)
      // this.$router.push({ path:"/Search",query: { name: 'word', input: this.input } })
      // 
    },
    cli_search_j(e) {
      if (e.key == 'Enter') {
        let url = this.SearchEngines[this.SearchEngines_i].secondary[this.SearchEngines_i2].url + this.input
        window.open(url)
        // this.$router.push({ path:"/Search"})
      }
    },
    querySearchAsync(queryString, cb) {
      var restaurants = this.restaurants;
      var results = queryString ? restaurants.filter(this.createStateFilter(queryString)) : restaurants;
      var url = 'https://suggestion.baidu.com/su?p=3&cb=iowenHot&_=1682697334283&wd=' + this.input;
      this.$jsonp(url, {
        callbackQuery: 'cb',
      }).then((response) => {
        this.querySearch = response;
        this.restaurants = response.s
        this.vlu_a = response.s
        this.restaurants = this.loadAll();
        restaurants = this.restaurants
        results = restaurants
        cb(results);
      }).catch((error) => {
      })
    },
    createStateFilter(queryString) {
      return (state) => {
        return (state.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    loadAll() {
      this.vlu_a2 = []
      this.vlu_a.forEach((item, index) => {
        this.vlu_o[index] = item
      });
      for (let key in this.vlu_o) {
        this.vlu_a2.push({
          value: this.vlu_o[key]
        })
      }
      return this.vlu_a2
    },
    handleSelect(item) {
      this.cli_search()
    }
  },
  mounted() {
    if (localStorage.getItem('SearchEngines_i2') != undefined) {
      this.SearchEngines_i2 = localStorage.getItem('SearchEngines_i2')
    }
    if (localStorage.getItem('SearchEngines_i') != undefined) {
      this.SearchEngines_i = localStorage.getItem('SearchEngines_i')
    }
    
  }
}
</script>
<style>
.Navigation_box {
  width: 60%;
  margin: auto;
}

.el-row {
  margin-bottom: 20px;

}

.el-col-3 {
  text-align: center;
  min-width: 57px !important;
}

.el-col {
  border-radius: 4px;
}

.bg-purple-dark {
  background: #99a9bf;
}

.bg-purple {
  background: #d3dce6;
  min-width: 57px !important;

}

:hover.bg-purple {
  background: #000;
  transition-duration: 500ms
}

.bg-purple-light {
  background: #e5e9f2;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
  text-align: center;
  line-height: 36px;
  /* font-size: max(1em,4vw); */
  font-size: min(1em, 2vw) !important;
  cursor: pointer;
  font-family: Arial, Helvetica, sans-serif;
}

:hover.grid-content {
  color: #f9fafc;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

.activeCss {
  background: skyblue !important;

}

.el-input-group--append .el-input__inner,
.el-input-group__prepend {
  height: 50px !important;
}
</style>