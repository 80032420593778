import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import Index from './views/index.vue'
import About from './views/about.vue'
import Technical from './views/Technical.vue'
import Upload from './views/Upload.vue'
import ElementUI from 'element-ui'
import axios from 'axios';
import Meta from "vue-meta";
import { VueJsonp } from 'vue-jsonp'
import 'element-ui/lib/theme-chalk/index.css'
// import './assets/css/fonts/linecons/css/linecons.css'
// import './assets/css/fonts/fontawesome/css/font-awesome.min.css'
import './assets/css/bootstrap.css'
import './assets/css/xenon-core.css'
import './assets/css/xenon-components.css'
import './assets/css/xenon-skins.css'
import './assets/css/nav.css'


const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.config.productionTip = false
Vue.use(VueRouter)
Vue.use(ElementUI)
Vue.use(VueJsonp)
Vue.use(Meta);

const routes = [
  { path: '/', redirect: '/index' },
  { path: '/index', component: Index },
  { path: '/about', component: About },
  { path: '/Technical', component: Technical },
  { path: '/Upload', component: Upload },

  {
    path: '/detail',
    component: () => import("./views/detail/index.vue"),
   
  }, {
    path: '/group',
    component: () => import("./views/detail/group.vue")
  }, {
    path: '/Search',
    component: () => import("./views/detail/Search.vue")
  },{
    path: '/update',
    component: () => import("./views/update.vue")
  },
]

const router = new VueRouter({
  mode: 'hash',
  routes,
})


new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
