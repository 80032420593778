<template>
  <div class="header-big canvas-fx mb-4" @keyup="cli_search_j">
    <iframe
      class="canvas-bg"
      scrolling="no"
      sandbox="allow-scripts allow-same-origin"
      src=""
    ></iframe>
    <div class="s-search">
      <div id="search" class="s-search mx-auto">
        <div id="search-list-menu" class="">
          <div class="s-type text-center">
            <div class="s-type-list big tab-auto-scrollbar overflow-x-auto">
              <div
                class="anchor"
                style="position: absolute; left: 50%; opacity: 0"
              ></div>
              <label
                for="type-big-zhannei"
                :class="{ active: tit_index == index }"
                data-page="home"
                data-id="group-z"
                v-for="(item, index) in SearchEngines"
                @click="tit_click(index, $event)"
                :key="index"
                ><span>{{ item.engine }}</span></label
              >
            </div>
          </div>
        </div>
        <input
          type="text"
          placeholder="输入关键字搜索"
          id="search-text"
          class="form-control smart-tips search-key"
          v-model="action_value"
        /><button type="submit" id="btn_search" @click="sous()">
          <img src="../assets/images/sous.png" alt="" />
        </button>
        <div id="search-list" class="hide-type-list">
          <div class="search-group justify-content-center group-a">
            <ul class="search-type tab-auto-scrollbar overflow-x-auto">
              <li
                v-for="(item, index) in SearchEngines[tit_index].secondary"
                @click="secondary_index(index)"
                :key="index"
              >
                <label
                  for="type-baidu"
                  :class="{ 'header-big_active': secondary == index }"
                  ><span class="text-muted">{{ item.name }}</span></label
                >
              </li>
            </ul>
          </div>
          <div class="card search-smart-tips" v-show="dis">
            <ul>
              <li
                v-for="(item, index) in vlu_a"
                @click="vlu_a_lit(index)"
                :key="index"
              >
                {{ item }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import elementResizeDetectorMaker from 'element-resize-detector'
export default {
  data() {
    return {
      SearchEngines: [
        {
          engine: "百度",
          secondary: [
            {
              name: "百度搜索",
              url: "https://www.baidu.com/s?tn=68018901_2_oem_dg&ie=utf-8&wd=",
            },
          ],
        },

        {
          engine: "抖音",
          secondary: [
            {
              name: "抖音搜索",
              url: "https://www.douyin.com/search/",
            },
          ],
        },
        {
          engine: "微信",
          secondary: [
            {
              name: "微信文章",
              url: "https://weixin.sogou.com/weixin?ie=utf8&s_from=input&_sug_=y&_sug_type_=&type=2&query=",
            },
            {
              name: "公众号",
              url: "https://weixin.sogou.com/weixin?ie=utf8&s_from=input&_sug_=y&_sug_type_=&type=1&query=",
            },
          ],
        },
        {
          engine: "知乎",
          secondary: [
            {
              name: "知乎搜索",
              url: "https://www.zhihu.com/search?type=content&q=",
            },
          ],
        },
        {
          engine: "研发",
          secondary: [
            {
              name: "CSDN",
              url: "https://so.csdn.net/so/search?spm=1000.2115.3001.4498&q=",
            },
          ],
        },
        {
          engine: "产品",
          secondary: [
            {
              name: "人人产品经理",
              url: "https://api.woshipm.com/search/list.html?key=",
            },
            {
              name: "七麦",
              url: "https://www.qimai.cn/search/index/country/cn/search/",
            },
          ],
        },
        {
          engine: "设计",
          secondary: [
            {
              name: "美叶",
              url: "https://www.meiye.art/search/",
            },
            {
              name: "花瓣",
              url: "https://huaban.com/search?q=",
            },
            {
              name: "Unsplash",
              url: "https://unsplash.com/s/photos/",
            },
            {
              name: "Freepik",
              url: "https://www.freepik.com/search?format=search&query=",
            },
          ],
        },
        {
          engine: "管理者",
          secondary: [
            {
              name: "36氪",
              url: "https://www.36kr.com/search/articles/",
            },
            {
              name: "凤凰网",
              url: "https://so.ifeng.com/?q=",
            },
            {
              name: "头条",
              url: "https://so.toutiao.com/search?dvpf=pc&source=input&keyword=",
            },
            {
              name: "央视网",
              url: "https://search.cctv.com/search.php?qtext=",
            },
          ],
        },
      ],
      tit_index: 0,
      secondary: 0,
      action_value: "",
      url_action_value: "",
      titlte_pageX: "0",
      vlu_a: [],
      dis: false,
      action_value2: "",
    };
  },
  methods: {
    tit_click(index, e) {
      this.tit_index = index;
      this.Caction_value();
      this.titlte_pageX = e.target.getBoundingClientRect().x;
      console.log(e);
      console.log(
        e.target.getBoundingClientRect().x -
          e.target.getBoundingClientRect().left
      );
    },
    secondary_index(index) {
      this.secondary = index;
      this.Caction_value();
    },
    Caction_value() {
      this.url_action_value =
        this.SearchEngines[this.tit_index].secondary[this.secondary].url +
        this.action_value;
      console.log(this.url_action_value);
    },
    cli_search_j(e) {
      if (e.key == "Enter") {
        window.open(this.url_action_value);
      }
    },
    sous() {
      window.open(this.url_action_value);
    },
    vlu_a_lit(index) {
      this.action_value = this.vlu_a[index];
      this.action_value2 = this.vlu_a[index];
      this.url_action_value =
        this.SearchEngines[this.tit_index].secondary[this.secondary].url +
        this.action_value;
      this.dis = false;
      this.vlu_a = [];
      console.log(this.dis, 1);
      this.sous();
    },
    sing() {
      this.dis = false;
    },
    querySearchAsync(queryString, cb) {
      var restaurants = this.restaurants;
      var results = queryString
        ? restaurants.filter(this.createStateFilter(queryString))
        : restaurants;
      var url =
        "https://suggestion.baidu.com/su?p=3&cb=iowenHot&_=1682697334283&wd=" +
        this.action_value;
      this.$jsonp(url, {
        callbackQuery: "cb",
      })
        .then((response) => {
          console.log(response.s);
          this.querySearch = response;
          this.restaurants = response.s;
          this.vlu_a = response.s;
          this.restaurants = this.loadAll();
          restaurants = this.restaurants;
          results = restaurants;
          cb(results);
        })
        .catch((error) => {});
    },
  },
  mounted() {
    document.getElementById("search-text").focus();
  },
  watch: {
    action_value(newValue, oldValue) {
      this.url_action_value =
        this.SearchEngines[this.tit_index].secondary[this.secondary].url +
        newValue;
      if (newValue == "") {
        this.dis = false;
      } else if (this.action_value2 == newValue) {
        this.dis = false;
      } else {
        this.dis = true;
      }
      console.log(this.dis, this.action_value);
      this.querySearchAsync();
    },
  },
};
</script>
<style>
/* @import '../assets/css/tbox.css'; */
@import "../assets/css/tbox.css";

#search {
  margin: auto;
}

.header-big #search button {
  top: 43px;
}

#search img {
  width: 20px;
  height: 20px;
}

.anchor {
  background: #888;
  border-radius: 100px;
  width: 100px !important;
  height: 4px;
  bottom: 5px;
  -webkit-transition: 0.25s;
  transition: 0.25s;
  z-index: 1000;
}
</style>