<template>
  <footer class="main-footer sticky footer-type-1" id="main-footer">
    <div class="footer-inner">
      <!-- 版权信息 -->
      <div class="footer-text">
        &copy; 2023 @ workdata.yijianbox.com <a href="#"><strong> 山西简单直接科技有限公司</strong></a>  版权所有 <a href="http://beian.miit.gov.cn/" target="_blank"><strong>晋ICP备2022012052号-1 </strong></a>
        
 
      </div>
      
      <div class="go-up">
        <a href="#" rel="go-top"><i class="fa-angle-up"></i></a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style>
  #main-footer{
    margin-top: 0px!important;
  }
</style>
