<template>
  <div style="height: 100%">
    <a class="navbar-brand" href="/" style="margin-right: 10px">返回WorkData</a>
    <div
      style="
        width: 800px;
        height: 100%;
        background-color: #fff;
        padding: 0 50px 50px 50px;
        overflow: hidden;
        margin: auto;
      "
    >
      <div class="Upload_title">上传效率软件</div>
      <div class="label_box">
        <div class="label_title label1">软件链接：</div>
        <div class="label_input">
          <el-input
            v-model="input_link"
            placeholder="请输入软件链接"
          ></el-input>
        </div>
        <!-- <div class="label_button" @click="extractWebsiteTitlename">
          自动识别
        </div> -->
      </div>
      <div class="label_box">
        <div class="label_title label1">软件名称：</div>
        <div class="label_input">
          <el-input
            v-model="input_name"
            placeholder="请输入软件名称"
          ></el-input>
        </div>
      </div>
      <div class="label_box">
        <div class="label_title label1">软件描述：</div>
        <div class="label_input">
          <el-input
            v-model="input_describe"
            placeholder="请输入软件描述"
          ></el-input>
        </div>
      </div>
      <!-- <div class="label_box" style="height: 80px">
     <div class="label_title label1">Logo：</div>
     <div class="label_input label_upload">

       <el-upload
         action
         list-type="picture-card"
         :on-preview="handlePictureCardPreview"
         :on-remove="handleRemove"
         :on-success="handleAvatarSuccess"
         :before-upload="beforeAvatarUpload"
         :file-list="fileList"
         :http-request="uploadHttpRequest"
         :on-change="onChange"
       >
         <i class="el-icon-plus"></i>
       </el-upload>
       <el-dialog :visible.sync="dialogVisible">
         <img width="100%" :src="dialogImageUrl" alt="" />
       </el-dialog>
     </div>
   </div> -->
      <div class="label_box">
        <div class="label_title label1">标签1：</div>
        <div class="label_input" style="margin: 0px 18px 0px 42px">
          <el-input
            v-model="input_label1"
            placeholder="请输入软件标签"
          ></el-input>
        </div>
      </div>
      <div class="label_box">
        <div class="label_title label1">标签2：</div>
        <div class="label_input" style="margin: 0px 18px 0px 42px">
          <el-input
            v-model="input_label2"
            placeholder="请输入软件标签"
          ></el-input>
        </div>
      </div>
      <div class="Upload_button" @click="Upload">确认上传</div>
      <div class="Audit_prompt">
        *审核预计1-3个工作日，加急请<span style="color: #1989fa;cursor: pointer;padding: 10px 0;" @mouseover="Audit_prompt_img" @mouseout="Audit_prompt_img_out">联系客服</span>
        <img v-show="d_Audit_prompt_img" class="Audit_prompt_img" src="../assets/images/wx.jpg" alt="">
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
export default {
  data() {
    return {
      imageUrl: "",
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      input_label1: "",
      input_label2: "",
      input_describe: "",
      input_name: "",
      input_link: "",
      fileLimit: "",
      fileList: [],
      file: "",
      hideUpload: "",
      fileImgUrl: "",
      id: "",
      d_Audit_prompt_img:false
    };
  },
  methods: {
    handleRemove(file) {
      let elUpload = document.querySelector(".el-upload--picture-card");
      setTimeout(() => {
        elUpload.style.display = "block";
      }, 1000);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleDownload(file) {},
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      let elUpload = document.querySelector(".el-upload--picture-card");
      elUpload.style.display = "none";
      return isLt2M;
    },
    handleAvatarSuccess(resource) {
      console.log(resource);
      this.file = file;
    },
    onChange(file, fileList) {
      // 以上提到照片墙可传入多个图片，但这里只需要传入一个，所以做处理
      // 且当已传入照片时，隐藏上传组件
      this.hideUpload = fileList.length >= this.limitCount;
      this.fileImgUrl = fileList[0].url;
      console.log(this.hideUpload, this.fileImgUrl);
      this.generateRandomId();
    },

    uploadHttpRequest(data) {
      console.log(this.fileList, "");
      const app = cloudbase.init({
        env: "feedbox-8gkm7lata4626018",
      });

      app
        .uploadFile({
          cloudPath: "workdata/" + this.id + ".png",
          filePath: this.fileImgUrl,
        })
        .then((res) => {
          console.log(res.fileID);
          app
            .getTempFileURL({
              fileList: [res.fileID],
            })
            .then((res) => {
              res.fileList.forEach((el) => {
                if (el.code === "SUCCESS") {
                  console.log(el.tempFileURL);
                } else {
                  //获取下载链接失败
                }
              });
            });
        });
    },
    Upload() {
      let that = this;
      console.log(this.file);
      let url = this.input_link;

      var pattern = /^(http|https):\/\/[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,}(\/\S*)?$/;
      if( !pattern.test(url)){
        this.$message.error("网址不合规");
         return
      }else if(that.input_describe==''){
        this.$message.error("描述不能为空");

      }else if(that.input_name==''){
        this.$message.error("名字不能为空");

      }else if(that.input_label1==''){
        this.$message.error("标签不能为空");

      }else if(that.input_label2==''){
        this.$message.error("标签不能为空");

      }
      
      
      const app = cloudbase.init({
        env: "feedbox-8gkm7lata4626018", // 此处填入您的环境ID
      });
      app
        .auth()
        .anonymousAuthProvider()
        .signIn()
        .then(() => {
          //huoqyu bid
          app
            .callFunction({
              // 云函数名称
              name: "hao",
              // 传给云函数的参数
              data: {
                type: "add_app",
                desc: that.input_describe,
                hname: that.input_name,
                link: that.input_link,
                logo: "",
                tag1: that.input_label1,
                tag2: that.input_label2,
              },
            })
            .then((res) => {
              console.log(res);
            });
        });
    },
    //32位随机ID
    generateRandomId() {
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let id = "";
      for (let i = 0; i < 32; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        id += characters.charAt(randomIndex);
      }
      this.id = id;
    },
    async extractWebsiteTitle(url) {
      try {
        const response = await fetch(url);
        const htmlText = await response.text();
        const titleRegex = /<title>(.*?)<\/title>/i;
        const match = htmlText.match(titleRegex);

        if (match && match[1]) {
          return match[1];
        }
        this.$message.error("无法获取网址名称");
        // return "无法获取网址标题";
      } catch (error) {
        this.$message.error("无法获取网址名称");
        // return "无法获取网址标题";
      }
    },
    extractWebsiteTitlename() {
      let url = this.input_link;
      var pattern = /^(http|https):\/\/[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,}(\/\S*)?$/;
      if( !pattern.test(url)){
        this.$message.error("网址不合规");

return
      }
     
      this.getMetakeywords(url)
      this.getMetaContent(url);
      this.extractWebsiteTitle(url)

        .then((title) => {
          this.input_name = title;
          // console.log(title); // 输出网址的标题
        })
        .catch((error) => {
          console.error(error);
        });

// axios.get('https://api.allorigins.win/get?url=' + encodeURIComponent('https://blog.csdn.net/rock_23/article/details/105777816'))
//         .then(response => {
//           const data = JSON.parse(response.data.contents);
//           this.title = data.title;
//         })
//         .catch(error => {
//           console.log(error);
//         });


    },
    getMetaContent(url) {
      var that = this
      console.log(url);
      var xhr = new XMLHttpRequest();
      xhr.onreadystatechange = async function () {
        if (xhr.readyState === 4 && xhr.status === 200) {
          const response = await fetch(url);
          const htmlText = await response.text();
          const titleRegex =
            /<meta[^>]+name="description"[^>]+content="([^"]*)"/i;
          const match2 = htmlText.match(titleRegex);
          // console.log(match2[0],'hsadjasd')
          const inputString = match2[0];
          const regex = /<meta[^>]+name="description"[^>]+content="([^"]*)"/i;
          const match = inputString.match(regex);
          if (match && match.length >= 2) {
            const descriptionContent = match[1];
            that.input_describe = descriptionContent
          } else {
          this.$message.error("无法提取description的content值");
          }
        }
      };
      xhr.open("GET", url);
      xhr.send();
    },
    getMetakeywords(url) {
      var that = this
      console.log(url);
      var xhr = new XMLHttpRequest();
      xhr.onreadystatechange = async function () {
        if (xhr.readyState === 4 && xhr.status === 200) {
          const response = await fetch(url);
          const htmlText = await response.text();
          const titleRegex =
            /<meta[^>]+name="keywords"[^>]+content="([^"]*)"/i;
          const match2 = htmlText.match(titleRegex);
         
          const inputString = match2[0];
          const regex = /<meta[^>]+name="keywords"[^>]+content="([^"]*)"/i;
          const match = inputString.match(regex);
          if (match && match.length >= 2) {
            const descriptionContent = match[1];
        
            var arr = descriptionContent.split(",");
            that.input_label1 = arr[0]
            that.input_label2 = arr[1]
          } else {
          this.$message.error("无法提取description的content值");
          }
        }
      };
      xhr.open("GET", url);
      xhr.send();
    },
    Audit_prompt_img(){
      this.d_Audit_prompt_img = true
    },
    Audit_prompt_img_out(){
      this.d_Audit_prompt_img = false

    }
  },
};
</script>
<style scoped>
::v-deep .el-upload__input {
  display: none;
}
::v-deep .el-upload--picture-card {
  width: 80px;
  height: 80px;
  line-height: 90px;
}
::v-deep .el-upload-list__item-thumbnail {
  width: 80px !important;
  height: 80px !important;
  /* line-height: 90px; */
}
::v-deep .el-upload-list__item {
  width: 80px !important;
  height: 80px !important;
  /* line-height: 90px; */
}
.Upload_title {
  height: 14px;
  font-size: 20px;
  font-family: MiSans-Bold, MiSans;
  font-weight: 700;
  color: #3d3d3d;
  line-height: 14px;
  -webkit-background-clip: text;
  margin: 80px 0 0 0;
  /* -webkit-text-fill-color: transparent; */
}
.label_box {
  width: 100%;
  height: 40px;
  margin: 32px 0 0px 0px;
}
.label_title {
  float: left;
  height: 40px;

  font-size: 16px;
  font-family: MiSans-Regular, MiSans;
  font-weight: 400;
  color: #4d4d4d;
  line-height: 40px;
  -webkit-background-clip: text;
  /* -webkit-text-fill-color: transparent;  */
}
.label_input {
  float: left;
  width: 240px;
  height: 40px;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 1px solid #dcdfe5;
  margin: 0px 17px 0px 19px;
}
.label_button {
  float: left;
  width: 86px;
  height: 40px;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 1px solid #1989fa;

  font-family: MiSans-Regular, MiSans;
  font-weight: 400;
  color: #1989fa;
  line-height: 40px;
  text-align: center;
}
.label_upload {
  width: 80px;
  height: 80px;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 1px solid #dcdfe5;
  margin: 0px 18px 0px 50px;
}
.Upload_button {
  width: 86px;
  height: 40px;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 1px solid #1989fa;
  font-size: 14px;
  font-family: MiSans-Regular, MiSans;
  font-weight: 400;
  color: #1989fa;
  line-height: 40px;
  text-align: center;
  margin: 43px 0 0 96px;
}
.Audit_prompt {
  height: 14px;
  font-size: 14px;
  font-family: MiSans-Regular, MiSans;
  font-weight: 400;
  color: #3d3d3d;
  line-height: 14px;
  margin: 43px 0 0 100px;
  position: relative;
}
.Audit_prompt_img{
  position: absolute;
  left: 220px;
  bottom: 15px;
  width: 200px;
}
.navbar-brand {
  font-weight: 900;
}
</style>