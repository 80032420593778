<template>
    <div @keyup="cli_search_j">
        <el-row :gutter="20">
            <el-col :span="3" v-for="(a, index) in SearchEngines" :key="index">
                <div class="grid-content bg-purple" @click="cSearchEngines(index)"
                    :class="{ 'activeCss': SearchEngines_i == index }">
                    {{ SearchEngines[index].engine }}</div>
            </el-col>
        </el-row>
        <div style="margin-top: 15px;margin-bottom: 15px;">
            <!-- <el-autocomplete placeholder="请输入内容" v-model="input" :popper-append-to-body="false" :trigger-on-focus="false"
                :autofocus="true" :fetch-suggestions="querySearch" class="input-with-select" @input="inp_l">
                <el-button slot="append" icon="el-icon-search" @click="cli_search()"></el-button>
            </el-autocomplete> -->
            <el-autocomplete
      class="inline-input"
      v-model="state2"
      :fetch-suggestions="querySearch"
      placeholder="请输入搜索内容"
      :trigger-on-focus="false"
      @select="handleSelect"
    ></el-autocomplete>
        </div>
        <el-row :gutter="20" type="flex" justify="center" align="middle">
            <el-col :span="3" v-for="(a, index) in SearchEngines[SearchEngines_i].secondary" :key="index">
                <div class="grid-content bg-purple" @click="cSearchEngines2(index)"
                    :class="{ 'activeCss': SearchEngines_i2 == index }">{{ a.name }}</div>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import axios from 'axios';
export default {

    name: "MTopNavigation",
    data() {
        return {
            input: '',
            SearchEngines: [{
                engine: '百度',
                secondary: [{
                    name: "百度搜索",
                    url: 'https://m.baidu.com/s?tn=68018901_2_oem_dg&ie=utf-8&wd='
                }],

            },

            {
                engine: '抖音',
                secondary: [{
                    name: "抖音搜索",
                    url: 'https://www.douyin.com/search/',
                }],


            },
            {
                engine: '微信',
                secondary: [{
                    name: "公众号",
                    url: "https://weixin.sogou.com/weixin?ie=utf8&s_from=input&_sug_=y&_sug_type_=&type=1&query="
                }, {
                    name: "微信文章",
                    url: "https://weixin.sogou.com/weixin?ie=utf8&s_from=input&_sug_=y&_sug_type_=&type=2&query="
                }],

            },
            {
                engine: '知乎',
                secondary: [{
                    name: "知乎搜索",
                    url: "https://www.zhihu.com/search?type=content&q="
                }],

            },
            {
                engine: '研发',
                secondary: [{
                    name: "CSDN",
                    url: "https://weixin.sogou.com/weixin?ie=utf8&s_from=input&_sug_=y&_sug_type_=&type=1&query="
                }],

            }, {
                engine: '产品',
                secondary: [{
                    name: "人人都是产品经理",
                    url: "https://api.woshipm.com/search/list.html?key="
                }, {
                    name: "七麦",
                    url: "https://www.qimai.cn/search/index/country/cn/search/"
                }],

            },
            {
                engine: '设计',
                secondary: [{
                    name: "美叶",
                    url: "https://www.meiye.art/search/"
                }, {
                    name: "花瓣",
                    url: "https://huaban.com/search?q="
                }, {
                    name: "Unsplash",
                    url: "https://unsplash.com/s/photos/"
                }, {
                    name: "Freepik",
                    url: "https://www.freepik.com/search?format=search&query="
                },],

            },
            {
                engine: '管理者',
                secondary: [{
                    name: "36氪",
                    url: "https://www.36kr.com/search/articles/"
                }, {
                    name: "凤凰网",
                    url: "https://so.ifeng.com/?q="
                }, {
                    name: "头条",
                    url: "https://so.toutiao.com/search?dvpf=pc&source=input&keyword="
                }, {
                    name: "央视网",
                    url: "https://search.cctv.com/search.php?qtext="
                },],

            },
            ],
            SearchEngines_i: 0,
            SearchEngines_i2: 0,
            querySearch: [],
            restaurants: [],
            state1: '',
            state2: ''

        }
    },
    methods: {
        cSearchEngines(i) {

            this.SearchEngines_i = i
            this.SearchEngines_i2 = 0
        },
        cSearchEngines2(i) {

            this.SearchEngines_i2 = i
        },
        cli_search() {
            let url = this.SearchEngines[this.SearchEngines_i].secondary[this.SearchEngines_i2].url + this.input
            window.open(url)
        },
        cli_search_j(e) {
            if (e.key == 'Enter') {
                let url = this.SearchEngines[this.SearchEngines_i].secondary[this.SearchEngines_i2].url + this.input
                window.open(url)
            }
        },
        inp_l() {
            var url = 'https://suggestion.baidu.com/su?p=3&cb=iowenHot&cb=jQuery3510270342102539584_1682697334282&wd=k&_=1682697334283' + this.input;
        this.$jsonp(url).then((response) => {
          this.users = response;
        }).catch((error) => {
        })
            // axios
            //     .get('https://suggestion.baidu.com/su?p=3&cb=iowenHot&cb=jQuery35105399875215770056_1682661687654&_=1682661687662&wd=' + this.input)
            //     .then(function (response) {
            //         console.log(response, 1);

            //     })
            //     .catch(function (error) {
            //         console.log(error, 2);
            //     });
        },
        querySearch(queryString, cb) {
            var restaurants = this.restaurants;
            var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
            // 调用 callback 返回建议列表的数据
            cb(results);
        },
        createFilter(queryString) {
            return (restaurant) => {
                return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
            };
        },
        loadAll() {
            return [
                { "value": "三全鲜食（北新泾店）", "address": "长宁区新渔路144号" },
                { "value": "Hot honey 首尔炸鸡（仙霞路）", "address": "上海市长宁区淞虹路661号" },
                { "value": "新旺角茶餐厅", "address": "上海市普陀区真北路988号创邑金沙谷6号楼113" },
                { "value": "泷千家(天山西路店)", "address": "天山西路438号" },
                { "value": "胖仙女纸杯蛋糕（上海凌空店）", "address": "上海市长宁区金钟路968号1幢18号楼一层商铺18-101" },
                { "value": "贡茶", "address": "上海市长宁区金钟路633号" },
                { "value": "豪大大香鸡排超级奶爸", "address": "上海市嘉定区曹安公路曹安路1685号" },
                { "value": "茶芝兰（奶茶，手抓饼）", "address": "上海市普陀区同普路1435号" },
                { "value": "十二泷町", "address": "上海市北翟路1444弄81号B幢-107" },
                { "value": "星移浓缩咖啡", "address": "上海市嘉定区新郁路817号" },
                { "value": "阿姨奶茶/豪大大", "address": "嘉定区曹安路1611号" },
                { "value": "新麦甜四季甜品炸鸡", "address": "嘉定区曹安公路2383弄55号" },
                { "value": "Monica摩托主题咖啡店", "address": "嘉定区江桥镇曹安公路2409号1F，2383弄62号1F" },
                { "value": "浮生若茶（凌空soho店）", "address": "上海长宁区金钟路968号9号楼地下一层" },
                { "value": "NONO JUICE  鲜榨果汁", "address": "上海市长宁区天山西路119号" },
                { "value": "CoCo都可(北新泾店）", "address": "上海市长宁区仙霞西路" },
                { "value": "快乐柠檬（神州智慧店）", "address": "上海市长宁区天山西路567号1层R117号店铺" },
                { "value": "Merci Paul cafe", "address": "上海市普陀区光复西路丹巴路28弄6号楼819" },
                { "value": "猫山王（西郊百联店）", "address": "上海市长宁区仙霞西路88号第一层G05-F01-1-306" },
                { "value": "枪会山", "address": "上海市普陀区棕榈路" },
                { "value": "纵食", "address": "元丰天山花园(东门) 双流路267号" },
                { "value": "钱记", "address": "上海市长宁区天山西路" },
                { "value": "壹杯加", "address": "上海市长宁区通协路" },
                { "value": "唦哇嘀咖", "address": "上海市长宁区新泾镇金钟路999号2幢（B幢）第01层第1-02A单元" },
                { "value": "爱茜茜里(西郊百联)", "address": "长宁区仙霞西路88号1305室" },
                { "value": "爱茜茜里(近铁广场)", "address": "上海市普陀区真北路818号近铁城市广场北区地下二楼N-B2-O2-C商铺" },
                { "value": "鲜果榨汁（金沙江路和美广店）", "address": "普陀区金沙江路2239号金沙和美广场B1-10-6" },
                { "value": "开心丽果（缤谷店）", "address": "上海市长宁区威宁路天山路341号" },
                { "value": "超级鸡车（丰庄路店）", "address": "上海市嘉定区丰庄路240号" },
                { "value": "妙生活果园（北新泾店）", "address": "长宁区新渔路144号" },
                { "value": "香宜度麻辣香锅", "address": "长宁区淞虹路148号" },
                { "value": "凡仔汉堡（老真北路店）", "address": "上海市普陀区老真北路160号" },
                { "value": "港式小铺", "address": "上海市长宁区金钟路968号15楼15-105室" },
                { "value": "蜀香源麻辣香锅（剑河路店）", "address": "剑河路443-1" },
                { "value": "北京饺子馆", "address": "长宁区北新泾街道天山西路490-1号" },
                { "value": "饭典*新简餐（凌空SOHO店）", "address": "上海市长宁区金钟路968号9号楼地下一层9-83室" },
                { "value": "焦耳·川式快餐（金钟路店）", "address": "上海市金钟路633号地下一层甲部" },
                { "value": "动力鸡车", "address": "长宁区仙霞西路299弄3号101B" },
                { "value": "浏阳蒸菜", "address": "天山西路430号" },
                { "value": "四海游龙（天山西路店）", "address": "上海市长宁区天山西路" },
                { "value": "樱花食堂（凌空店）", "address": "上海市长宁区金钟路968号15楼15-105室" },
                { "value": "壹分米客家传统调制米粉(天山店)", "address": "天山西路428号" },
                { "value": "福荣祥烧腊（平溪路店）", "address": "上海市长宁区协和路福泉路255弄57-73号" },
                { "value": "速记黄焖鸡米饭", "address": "上海市长宁区北新泾街道金钟路180号1层01号摊位" },
                { "value": "红辣椒麻辣烫", "address": "上海市长宁区天山西路492号" },
                { "value": "(小杨生煎)西郊百联餐厅", "address": "长宁区仙霞西路88号百联2楼" },
                { "value": "阳阳麻辣烫", "address": "天山西路389号" },
                { "value": "南拳妈妈龙虾盖浇饭", "address": "普陀区金沙江路1699号鑫乐惠美食广场A13" }
            ];
        },
        handleSelect(item) {
            
        },
        mounted() {
            this.restaurants = this.loadAll();
        }
    },
}
</script>
<style>
.el-row {
    margin-bottom: 20px;

}

.el-col {
    border-radius: 4px;
}

.bg-purple-dark {
    background: #99a9bf;
}

.bg-purple {
    background: #d3dce6;
}

:hover.bg-purple {
    background: #000;
    transition-duration: 500ms
}

.bg-purple-light {
    background: #e5e9f2;
}

.grid-content {
    border-radius: 4px;
    min-height: 36px;
    text-align: center;
    line-height: 36px;
    font-size: 8px;
    cursor: pointer;
}

:hover.grid-content {
    color: #f9fafc;
}

.row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
}

.activeCss {
    background: skyblue !important;
}

::v-deep.el-autocomplete {
    display: block !important;
}
</style>