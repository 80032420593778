<template>
    <div class="boxed-container">
      <nav class="navbar horizontal-menu navbar-fixed-top">
        <div class="navbar-inner">
          <div class="navbar-brand">
            <router-link to="/" class="logo">
              <img src="../assets/images/workdatabig.png" width="60%" alt="" class="hidden-xs">
              <img src="../assets/images/workdata-min.png" width="100%" alt="" class="visible-xs">
            </router-link>
          </div>
          <div class="navbar-mobile-clear"></div>
          <!-- <a href="https://github.com/Anjaxs/WebStack-vue" target="_blank">
             <img style="position: absolute; top: 0; right: 0; border: 0;"
                 src="https://s3.amazonaws.com/github/ribbons/forkme_right_darkblue_121621.png"
                 alt="Fork me on GitHub">
          </a> -->
        </div>
      </nav>
  
      <div class="page-container">
        <div class="main-content" style="">
          <div class="row">
            <div class="col-md-12">
              <div class="panel panel-default">
                <!-- 关于WorkData -->
                <h4 class="text-gray">企业网站选型</h4>
                <div class="panel-body">
                 
                  <!-- 关于站长 -->
                  <!-- <h4 class="text-gray">微信扫码提意见：</h4> -->
                  <img src="../assets/images/jianshe.png" alt="" class="yijianbox_logo">
                   <div class="row">
                    <div class="col-sm-12">
                      <blockquote>
                        <!-- <p>WorkData企业导航希望致力于帮助企业解决软件服务选型、工作常用网站查找、优质内容搜索，日常工作质量和效率提升的问题，我们相信，一切问题的解决第一步都是更优质的信息内容和清晰信息获取的提升。</p>
                        <br />
                        <p>使用中有任何新功能需求、内容需求和想法建议都可以扫码提给我们，我们将倾听您的意见，认真探索，并作出行动</p> -->
                        <p>功能建设中</p>
                        <p>预计5月28日00：00开放</p>
                      </blockquote>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import Footer from '../components/Footer.vue'
  
  export default {
    name: 'About',
    components: {
      Footer
    },
    data() {
      return {
        authorImg: './assets/images/logos/viggoz.png'
      }
    }
  }
  </script>
  
  <style>
  .panel-body{
    margin: auto;
  }
  .yijianbox_logo{
    display: block;
    margin: auto;
    width: 100px;
    height: 100px;
  }
  .col-sm-12 p{
    text-align: center;
  }
  </style>
  