<template>
    <div class="iframe_box">
        <iframe id="iframe" :src="link" frameborder="0" marginwidth="0" marginheight="0" hspace="0" vspace="0"
            scrolling="auto" width="50%" height="50%"
            style="OVERFLOW:SCROLL;OVERFLOW-X:HIDDEN;OVERFLOW-Y:HIDDEN" v-if="if_Iframe"></iframe>
    </div>
</template>
<script>
export default {
    props: {
        link: String,
        if_Iframe:Boolean
    },
    created() {
      
    },
    methods: {
       
    }
}
</script>
<style>
.iframe_box {
    width: 1300px;
    height: 675px;
    overflow: hidden;
    border: 1px solid #898888;
}

#iframe {
    /* position: sticky;
    top: 0; */
    width: calc(100% + 17px);
    height: 675px;
}
</style>