<template>
  <router-view></router-view>
</template>
<script>
var _hmt = _hmt || [];
(function() {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?181b2d56cf41a8d4e9262a313c2bacbc";
  var s = document.getElementsByTagName("script")[0]; 
  s.parentNode.insertBefore(hm, s);
})();
</script>

<script>

export default {
  name: 'App'
}
</script>

<style>
.navbar-brand img{
  width: 100%;
}
div .el-autocomplete{
  display: block !important;
  
}
</style>
