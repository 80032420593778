<template>
  <div class="boxed-container">
    <nav class="navbar horizontal-menu navbar-fixed-top">
      <div class="navbar-inner">
        <div class="navbar-brand">
          <router-link to="/" class="logo">
            <img src="../assets/images/workdatabig.png" width="60%" alt="" class="hidden-xs">
            <img src="../assets/images/workdata-min.png" width="100%" alt="" class="visible-xs">
          </router-link>
        </div>
        <div class="navbar-mobile-clear"></div>
        <!-- <a href="https://github.com/Anjaxs/WebStack-vue" target="_blank">
           <img style="position: absolute; top: 0; right: 0; border: 0;"
               src="https://s3.amazonaws.com/github/ribbons/forkme_right_darkblue_121621.png"
               alt="Fork me on GitHub">
        </a> -->
      </div>
    </nav>

    <div class="page-container">
      <div class="main-content" style="">
        <div class="row">
          <div class="col-md-12">
            <div class="panel panel-default">
              <!-- 关于WorkData -->
              <h4 class="text-gray">关于WorkData</h4>
              <div class="panel-body">
                <div class="row">
                  <div class="col-sm-12">
                    <blockquote>
                      <p class="values">WorkData企业导航希望致力于帮助企业解决软件服务选型、工作常用网站查找、优质内容搜索，日常工作质量和效率提升的问题，我们相信，一切问题的解决第一步都是更优质的信息内容和清晰信息获取的提升。</p>
                      
                      <!-- <p class="values">打开速度过慢的，打开有弹出且难关闭，阻挡视线不收录</p>
                      <p class="values">评估无法提升工作效率的不收录</p> -->
                      <!-- <p>看不懂有什么功能的不收录</p> -->
                      <!-- <br /> -->
                      <!-- <p>使用中有任何新功能需求、内容需求和想法建议都可以扫码提给我们，我们将倾听您的意见，认真探索，并作出行动</p> -->
                    </blockquote>
                  </div>
                  
                </div>
              <h4 class="text-gray" style="font-size: 18px;">收录原则</h4>

                <div class="row">
                  <div class="col-sm-12">
                    <blockquote>
                      <!-- <p class="values">WorkData企业导航希望致力于帮助企业解决软件服务选型、工作常用网站查找、优质内容搜索，日常工作质量和效率提升的问题，我们相信，一切问题的解决第一步都是更优质的信息内容和清晰信息获取的提升。</p> -->
                      <p class="values">1. 原则上只收录对工作效率提升有帮助的</p>
                      
                      <p class="values">2. 软件存在打开速度过慢的，打开后会弹窗且很难找到关闭按钮，影响使用主要功能的，不进行收录</p>
                      <p class="values">3. 经专家组评估后，认为对提升工作效率无显著帮助的，不进行收录</p>
                      <!-- <p>看不懂有什么功能的不收录</p> -->
                      <br />
                      <p>使用中有任何新功能需求、内容需求和想法建议都可以扫码提给我们，我们将倾听您的意见，认真探索，并作出行动</p>
                    </blockquote>
                  </div>
                  
                </div>
                
                <!-- 关于站长 -->
                <h4 class="text-gray">微信扫码提意见：</h4>
                <img src="../assets/images/yijianbox_logo.jpg" alt="" id="yijianbox_logo">
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  </div>
</template>

<script>
import Footer from '../components/Footer.vue'

export default {
  name: 'About',
  components: {
    Footer
  },
  data() {
    return {
      authorImg: './assets/images/logos/viggoz.png'
    }
  },
  metaInfo() {
    return {
      title:'关于WorkData',
      
    };
  },
}
</script>

<style>
.panel-body{
  margin: auto;
}
#yijianbox_logo{
  display: block;
  margin: auto;
  width: 200px;
  height: 200px;
}
.values{
  text-align: left !important;
  color: #000 !important;
}
</style>
