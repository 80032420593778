<template>
  <div class="page-container" @click="index_none()">
    <div class="sidebar-menu toggle-others fixed">
      <div class="sidebar-menu-inner">
        <header class="logo-env">
          <!-- logo -->
          <div class="logo">
            <a href="javascript:void(0)" class="logo-expanded">
              <img src="../assets/images/workdata.png" width="100%" alt="" />
            </a>
            <a href="javascript:void(0)" class="logo-collapsed">
              <img src="../assets/images/workdata-min.png" width="40" alt="" />
            </a>
          </div>
        </header>
        <!-- 侧边栏 -->
        <ul id="main-menu" class="main-menu">
          <li>
            <a :href="'#' + '工作台'" class="smooth">
              <i class="linecons-star"></i>
              <span class="title">工作台</span>
            </a>
<!-- 
            <div @click="linkRou()" class="linkRou">
              <i class="linecons-star"></i>
              <span class="title">技术选型</span>
            </div> -->

            <div class="linkRou" @click="scrollToPosition(index)" v-for="(item, index) in welist" :key="index">
              <!-- <el-tag
               
                class="tag_g"
                :class="index == 0 ? 'tag_g_active' : ''"
             
               
                >{{ welist[index].a_name }}
              </el-tag> -->
              <i class="linecons-star"></i>
              <span class="title">{{ welist[index].a_name }}</span>
            </div>
          </li>
          <!-- 关于本站 -->
          <li class="submit-tag">
            <router-link to="/about">
              <i class="linecons-heart"></i>
              <span class="tooltip-blue">意见反馈</span>
              <span
                class="label label-Primary pull-right hidden-collapsed"
              ></span>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="main-content">
      <nav class="navbar user-info-navbar" role="navigation">
        <ul class="user-info-menu left-links list-inline list-unstyled">
          <li class="hidden-sm hidden-xs">
            <a href="javascript:void(0)" data-toggle="sidebar"
              ><i class="fa-bars"></i
            ></a>
          </li>
        </ul>
        <!-- <div class="sculpture">
          <img src="../assets/images/mini.png" alt="" />
        </div> -->
        <router-link :to="{ path: 'Upload' }"
          ><div class="Upload">上传</div></router-link
        >
        <el-select
          v-model="value"
          filterable
          placeholder="产品"
          @change="select(value)"
        >
          <el-option
            v-for="(item, index) in options"
            :key="index"
            :label="item.r_name"
            :value="{ value: item._id, label: item.r_name, index: index }"
          >
          </el-option>
        </el-select>
        <div class="clss">身份：</div>
       
        <router-link :to="{ path: '/update' }"
          ><div class="clss">更新时间：1天前</div></router-link
        >
        
          <div class="clss" style="text-align: center;">可将workdata设置为浏览器主页，打开即用</div>
        
      </nav>
      <!-- <TopNavigation v-if="screenWidth >= 750" /> -->
      <!-- <MTopNavigation v-else /> -->
      <Tsearch ref="child" />

      <div class="Ceiling">
        <div class="Ceiling_left"></div>
        <div class="Ceiling_right"></div>
        <div class="tag_g">
          <el-tag
            v-for="(item, index) in welist"
            class="tag_g"
            :class="index == 0 ? 'tag_g_active' : ''"
            @click="scrollToPosition(index)"
            :key="index"
            >{{ welist[index].a_name }}
          </el-tag>
        </div>
      </div>
      <div v-for="(item, idx) in welist" :key="idx">
        <div class="titleT">
          {{ item.a_name }}
          <p class="btn-primary" id="share" @click="DB_bu(idx)">聚合分享</p>
          <p class="btn-primary" id="polymerization" @click="share(item._id)">
            聚合打开
          </p>
        </div>
        <WebItem :item="item" :idx="idx" />
      </div>
      <!-- <Iframe></Iframe> -->
      <Footer />
    </div>
    <div id="Collection" class="Collection" v-if="ctrlD">
      <div class="tips-title">
        <div>Ctrl+D</div>
        <div class="close" @click="CtrlD()"></div>
      </div>
      <div class="tips-content1">快速收藏不迷路</div>
      <div class="tips-content2">节省办公时间</div>
    </div>
  </div>
</template>

<script>
import WebItem from "../components/WebItem.vue";
import Footer from "../components/Footer.vue";
import TopNavigation from "../components/TopNavigation.vue";
import MTopNavigation from "../components/MTopNavigation.vue";
import Tsearch from "../components/tsearch.vue";
import Iframe from "../components/iframe.vue";
import { loadJs } from "../assets/js/app.js";
import cloudbase from "@cloudbase/js-sdk";

export default {
  name: "Index",
  components: {
    WebItem,
    Footer,
    TopNavigation,
    MTopNavigation,
    Iframe,
    Tsearch,
  },
  data() {
    return {
      items: [],
      lang: {},
      langList: [
        {
          key: "zh",
          name: "简体中文",
          flag: "./assets/images/flags/flag-cn.png",
        },
        {
          key: "en",
          name: "English",
          flag: "./assets/images/flags/flag-us.png",
        },
      ],
      index_data: [],
      options: [],
      // value:"",
      value: "产品",
      activeName: "second",
      welist: "",
      select_value: "",
      select_name: "产品",
      ctrlD: true,
    };
  },
  created() {
    this.lang = this.langList[0];
    loadJs();
    this.getInfo();
    this.getInfo1();
    this.screenWidth = document.body.clientWidth;
  },

  methods: {
    scrollToPosition(index) {
      document.getElementsByClassName("titleT")[index].scrollIntoView();
      document.documentElement.scrollTop -= "85";
    },
    select(id) {
      let _id;
      let str = typeof id;
      if (id == undefined) {
        _id = "";
      } else if (typeof id == "string") {
        let pf = parseFloat(id);
        _id = this.options[pf]._id;
      } else {
        _id = this.options[id.index]._id;
        localStorage.setItem("index", id.index);
      }
      let self = this;
      const app = cloudbase.init({
        env: "feedbox-8gkm7lata4626018", // 此处填入您的环境ID
      });
      app
        .auth()
        .anonymousAuthProvider()
        .signIn()
        .then(() => {
          //huoqyu bid
          app
            .callFunction({
              // 云函数名称
              name: "hao",
              // 传给云函数的参数
              data: {
                type: "get_app_album",
                _id: _id,
              },
            })
            .then((res) => {
              let welist = res.result.list;
              this.welist = welist;
            });
        });
    },
    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.options = this.list.filter((item) => {
            return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1;
          });
        }, 200);
      } else {
        this.options = [];
      }
    },

    linkRou() {
      this.$router.push("/Technical");
    },
    transName(webItem) {
      return this.lang.key === "en" ? webItem.en_name : webItem.name;
    },
    getInfo() {
      let self = this;
      const app = cloudbase.init({
        env: "feedbox-8gkm7lata4626018", // 此处填入您的环境ID
      });
      app
        .auth()
        .anonymousAuthProvider()
        .signIn()
        .then(() => {
          //huoqyu bid
          app
            .callFunction({
              // 云函数名称
              name: "hao",
              // 传给云函数的参数
              data: {
                type: "get_hlist",
              },
            })
            .then((res) => {
              this.index_data = res.result.hlist.data;
              self.sortWebsite(res.result.hlist.data);
            });
        });
    },
    //下拉列表
    getInfo1() {
      let self = this;
      const app = cloudbase.init({
        env: "feedbox-8gkm7lata4626018", // 此处填入您的环境ID
      });
      app
        .auth()
        .anonymousAuthProvider()
        .signIn()
        .then(() => {
          //huoqyu bid
          app
            .callFunction({
              // 云函数名称
              name: "hao",
              // 传给云函数的参数
              data: {
                type: "get_role",
              },
            })
            .then((res) => {
              let data = res.result.role_list.data;
              self.options = data;
              if (localStorage.getItem("index") == undefined) {
                this.value = data[0].r_name;
              } else {
                this.value = data[localStorage.getItem("index")].r_name;
              }
              this.select(localStorage.getItem("index"));
            });
        });
    },
    sortWebsite(arr) {
      if (arr.length > 0) {
        const webArr = [];
        const obj1 = {
          name: "工作台",
          en_name: "Recommended",
          icon: "linecons-star",
          web: [],
        };
        const obj2 = {
          name: "技术选型",
          en_name: "Recommended",
          icon: "linecons-star",
          web: [],
        };
        const obj3 = {
          name: "意见反馈",
          en_name: "Recommended",
          icon: "linecons-star",
          web: [],
        };
        arr.forEach((item) => {
          let otype = {
            url: item.link,
            logo: item.logo,
            title: item.hname,
            desc: item.desc,
            id: item._id,
          };
          if (item.type == 1) {
            obj1.web.push(otype);
          }
          if (item.type == 2) {
            obj2.web.push(otype);
          }
        });
        webArr.push(obj1);
        // webArr.push(obj2);
        this.items = webArr;
      }
    },
    open() {
      const h = this.$createElement;
      this.$msgbox({
        title: "登录",

        message: h("p", null, [h("div", null, "内容可以是 ")]),
        showCancelButton: true,
        center: true,
        cancelButtonText: false,
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "执行中...";
            setTimeout(() => {
              done();
              setTimeout(() => {
                instance.confirmButtonLoading = false;
              }, 300);
            }, 3000);
          } else {
            done();
          }
        },
      }).then((action) => {
        this.$message({
          type: "info",
          message: "action: " + action,
        });
      });
    },
    share(_id) {
      this.$router.push({ path: "/group", query: { _id: _id } });
    },
    DB_bu(index) {
      let ID = this.welist[index]._id;
      // console.log(index);
      var aux = document.createElement("input");
      aux.setAttribute(
        "value",
        "https://workdata.yijianbox.com/#/group?_id=" + ID
      );
      document.body.appendChild(aux);
      aux.select();
      document.execCommand("copy");
      document.body.removeChild(aux);
      this.$message({
        message: "复制分享链接成功",
        type: "success",
      });
      // this.$message({
      //   message: '复制分享链接失败',
      //   type: 'success'
      // });
    },
    index_none(w) {
      this.$refs.child.sing();
    },
    //点击ctrlD隐藏
    CtrlD() {
      console.log(this.ctrlD);
      this.ctrlD = false;
    },
    //跳转上传页面
    Upload() {},
  },
};
</script>

<style>
#Collection {
  position: fixed;
  height: 154px;
  top: 300px;
  right: 10px;
  z-index: 1000;
  text-align: center;
  width: 93px;
  box-sizing: border-box;
  background-image: url(../assets/images/Group275.png);
  background-repeat: repeat-y;
  cursor: pointer;
}
#Collection .tips-title {
  width: 100%;
  font-size: 14px;
  line-height: 30px;
  color: #fff;
  margin-top: 40px;
  position: relative;
}
#Collection .tips-title .close {
  position: absolute;
  width: 0;
  height: 0;
  top: 4px;
  right: 10px;
}
#Collection .tips-title .close:before {
  content: "";
  width: 2px;
  height: 10px;
  position: absolute;
  top: 0;
  right: 0;
  background: #fff;
  transform: rotate(-45deg);
}

#Collection .tips-title .close:after {
  content: "";
  width: 2px;
  height: 10px;
  background: #fff;
  position: absolute;
  top: 0;
  right: 0;
  transform: rotate(45deg);
}
#Collection .tips-content1 {
  padding: 5px 8px;
  font-size: 16px;
  font-family: Inter-Extra Bold, Inter;
  font-weight: 400;
  color: #018eff;
}
#Collection .tips-content2 {
  font-size: 12px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #91bee1;
  padding: 4px 0 9px;
}
.butt {
  float: right;
  margin-top: 5vh;
  margin-right: 5vw;
}

.linkRou {
  cursor: pointer;
}

.linkRou :hover {
  color: #ffffff;
}

.el-input--suffix {
  margin-top: 17px;
  float: right;
}

.sculpture {
  float: right;
  display: inline-block;
  margin-top: 10px;
  margin-right: 10px;
  margin-left: 10px;
}

.el-select {
  float: right;
}

.sculpture img {
  width: 50px;
  height: 50px;
}

.Ceiling {
  position: sticky;
  top: -1px;
  z-index: 100;
  width: 100%;
  background: #ffffff;
  margin-bottom: 20px;
}

.tag_g {
  margin: 10px;
  /* margin-left: 0px; */
  cursor: pointer;
  display: inline-block;
}

.tag_g::first-child {
  margin-left: -10px !important;
  cursor: pointer;
  display: inline-block;
}

.Ceiling_left {
  width: 30px;
  height: 100%;
  position: absolute;
  background-color: #ffffff;
  left: -30px;
  float: right;
}

.Ceiling_right {
  width: 30px;
  height: 100%;
  position: absolute;
  background-color: #ffffff;
  right: -30px;
  float: right;
}

.el-autocomplete-suggestion {
  top: 128px !important;
}

.clss {
  float: right;
  height: 100%;
  /* width: 80px; */
  text-align: center;
  line-height: 74px;
  /* text-align: c; */
  margin-left: 40px;
}

.tag_g_active {
  margin-left: -10px;
}

.titleT {
  height: 32px;
}

.titleT .btn-primary {
  float: right;
  margin-bottom: 0px;
  margin-right: 10px;
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 13px;
  line-height: 1.42857143;
  border-radius: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.el-input__inner {
  cursor: text !important;
}
.user-info-navbar {
  margin-bottom: 0px !important;
}
.user-info-navbar .user-info-menu > li > a {
  top: 0 !important;
}
.Upload {
  padding: 10px 20px;
  float: right;
  background-color: #409EFF;
  margin:16px 30px 0 20px;
  /* margin-right: 30px; */
  border-radius: 4px;
  font-size: 16px;
  color: #ffffff;
  /* text-align: center; */
}
</style>
