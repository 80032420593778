<template>
  <div>
    <div class="row">
      <div class="box2_father" v-for="(web, idx) in item.album" :key="idx">
        <div class="col-sm-3" @mouseover="Functionbox(idx + 1)" @mouseout=Functionbox2()>
          <!-- <div  v-for="(web1, idx) in web" >111 </div> -->
          <div class="xe-widget xe-conversations box2 label-info" :class="dFunctionbox - 1 === idx ? 'box2_active' : ''"
            title="" @click="openweb(web._id)" data-toggle="tooltip" data-placement="bottom"
            :data-original-title="web._id">
            <div class="xe-comment-entry">
              <div class="xe-user-img">
                <img :src="web.logo" class="lozad" width="60" />
              </div>
              <div class="xe-comment">
                <div class="xe-user-name overflowClip_1">
                  <strong>{{ web.hname }}</strong>
                  <p class="overflowClip_2">{{ web.desc }}</p>
                </div>
              </div>
            </div>
            <div class="Functionbox" @mouseover="Functionbox(idx + 1, 1)" @mouseout=Functionbox2()
              v-show="dFunctionbox == idx + 1">
              <span class="DB_bu" @click.stop="DB_bu()">分享</span> 
              <span class="DB_bu" @click.stop="DB_zid(web.link)">直达</span>
              <!-- <span class="DB_bu" @click.stop="open()">提意见</span> -->
              <span class="DB_bu">打开</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />
  </div>
</template>

<script>
export default {
  data() {
    return {
      dFunctionbox: 0

    }
  },

  name: "WebItem",
  props: {
    item: Object,
    transName: Function,
    index_data: Array,
    welist: Array,
    idx: Number,
  },

  methods: {
    openweb(id) {

      this.$router.push({
        path: "detail",
        query: {
          id
        }
      })
    },
    Functionbox(i, o) {
      this.dFunctionbox = i
    },
    Functionbox2() {
      this.dFunctionbox = 0
    },
    DB_bu() {
      let ID = this.item.album[this.dFunctionbox - 1]._id
      let url = window.location.host
      var aux = document.createElement("input");
      aux.setAttribute("value", "https://workdata.yijianbox.com/detail?id=" + ID);
      document.body.appendChild(aux);
      aux.select();
      document.execCommand("copy");
      document.body.removeChild(aux);
      this.$message({
        message: '复制分享链接成功',
        type: 'success'
      });
      // this.$message('');
      // alert("");
    },
    DB_zid(e){
      window.open(e);
      // console.log(e)
    },
    open() {
      this.$prompt('请输入邮箱', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',

        inputErrorMessage: '邮箱格式不正确'
      }).then(({ value }) => {
        this.$message({
          type: 'success',
          message: '你的邮箱是: ' + value
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消输入'
        });
      });
    },

  },
  created() {
  },
};
</script>

<style scoped>
i {
  margin-right: 7px;
}

.col-sm-3 {
  position: relative;
  z-index: 10;

}

.Functionbox {
  width: 100%;
  height: 20px;
  /* background-color: #87ceeb54; */
  position: absolute;
  z-index: 10000;
  top: 85px;
  left: 0px;
  cursor: pointer;
}

.DB_bu {
  display: inline-block;
  width: 33%;
  text-align: center;
  /* height: 40px; */
  line-height: 20px;
  
}

.box2_active {
  height: 110px;
}

.col-sm-3 {
  position: relative;
  /* position: absolute; */
  height: 86px;
  margin-bottom: 30px;
}

.xe-user-img {
  height: 2px;
  float: left;
  display: flex;
  /* 主轴居中 */
  justify-content: center;
  /* 从轴居中 */
  align-items: center;
margin-top: 25px;
  /* line-height: 56px; */
  /* text-align: center; */
}

.xe-user-img img {
  border-radius:4px;
}
</style>
